// import React, { useEffect } from 'react';

// const Disclaimer = () => {
//     useEffect(() => {
//         const script = document.createElement('script');
//         script.id = 'termly-jssdk';
//         script.src = 'https://app.termly.io/embed-policy.min.js';
//         script.async = true;

//         const tjs = document.getElementsByTagName('script')[0];
//         tjs.parentNode.insertBefore(script, tjs);

//         return () => {
//             if (document.getElementById('termly-jssdk')) {
//                 document.getElementById('termly-jssdk').remove();
//             }
//         };
//     }, []);

//     return <div name="termly-embed" data-id="7e78a25b-a33a-40a5-ad6a-45c921c0e6d6"></div>;
// };

// export default Disclaimer;

import React from 'react';

const Disclaimer = () => {
    return (
        <iframe
            src="https://app.termly.io/policy-viewer/policy.html?policyUUID=7e78a25b-a33a-40a5-ad6a-45c921c0e6d6"
            width="100%"
            height="100%"
            style={{ border: 'none' }}
            title="Disclaimer"
        />
    );
};

export default Disclaimer;

