class Walkbook {

  constructor() {
    this.baseURL = process.env.REACT_APP_API_BASE_URL;
  }


  createWalkbook = async (token, name, creationMethod, geoData, filterParams, campaign_id, user_id) => {

    // const token = localStorage.getItem('firebase_token');

    // Create data JSON
    const formData = {
      "name": name,
      "creation_method": creationMethod,
      "geo_data": geoData[0],
      "filter_params": filterParams,
      "campaign_id": campaign_id,
      "user_id": user_id,
    };

    if (token) {
      // Create request
      const request = new Request(`${this.baseURL}/walkbook/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      // Fetch request
      const response = await fetch(request);
      // console.log("response: ", response);
      const data = await response.json();
      // console.log("data: ", data);

      // 500 error handling
      if (response.status === 500) {
        throw new Error('Internal server error');
      }

      // 400 error handling
      if (response.status >= 400 && response.status < 500) {
        // console.log("data.detail: ", data.detail); 
        if (data.detail) {
          throw new Error(data.detail);
        }

        // console.log("typeof data: ", typeof data);
        if (typeof data === 'string') {
          throw new Error(data);
        }

        if (typeof data === 'object') {
          if (data['error'] === 'NAME_EXISTS') {
            throw new Error('This name is already in use. Please choose a different name.');
          }
          // throw new Error(data);
        }

        throw new Error('An error occurred');
      }

      return data;
    } else {
      console.error("No token found");
    }
  };



  getWalkbook = async (token, id, campaign_id) => {
    if (!id || !campaign_id) {
      throw new Error('ID or campaign_id was not provided');
    }
  
    // Assume `currentUser` is available globally or passed to this function
    // If currentUser is null or undefined, handle accordingly
    if (token) {

      try {
    
        const request = new Request(`${this.baseURL}/walkbook/?id=${id}&campaign_id=${campaign_id}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
        });
    
        const response = await fetch(request);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching walkbook:", error);
        throw error;
      }
    }
    else {
      console.error("No token found");
      return;
    }
  };



  getOptimizedWalkbook = async (token, id, campaign_id, start_location) => {

    if (token) {

      if (id && campaign_id) {

        // let queryParams = new URLSearchParams({
        //   id: id,
        //   campaign_id: campaign_id,
        // });
  
        // if (start_location) {
        //   queryParams.append('start_location', start_location);
        // }
  
        // // Create request with query parameters
        // const request = new Request(`${this.baseURL}/walkbook/optimized/?${queryParams.toString()}`, {
        //   method: 'GET',
        //   headers: {
        //     'accept': 'application/json',
        //     'Authorization': `Bearer ${token}`,
        //     'X-client_type': 'web'
        //   },
        //   credentials: 'include',  // Assume credentials might be needed
        // });


        console.log('start_location: ', start_location);

        // Constructing URL with embedded parameters directly
        let url = `${this.baseURL}/walkbook/optimized/?id=${id}&campaign_id=${campaign_id}`;
        if (start_location) {
          url += `&lat=${start_location[1]}&lng=${start_location[0]}`; // Assuming start_location is an array [lat, lng]
          // url += `&start_location=${start_location}`;
          // url += `&start_location=${start_location[0]},${start_location[1]}`; // Assuming start_location is an array [lat, lng]
        }

        console.log('url: ', url);

        // Create request with query parameters
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          },
          credentials: 'include',  // Assume credentials might be needed
        });

        const response = await fetch(request);
        if (response.status === 500) {
          throw new Error('Internal server error');
        }

        const data = await response.json();

        if (response.status >= 400 && response.status < 500) {
          if (data.detail) {
            throw data.detail;
          }

          throw data;
        }

        return data;

      } else {
        throw new Error('Data was not provided');
      }

    } else {
      console.error("No token found");

    }
  };



  getMinimalWalkbook = async (token, id, campaign_id) => {
    //const token = localStorage.getItem('token');
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (id && campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/walkbook/minimal/?id=` + id + '&campaign_id=' + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
          //headers: {'Authorization': `Bearer ${token}`}
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        //console.log(data);
        return data;
      }
      else {
        throw new Error('ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };



  getAllWalkbooks = async (token, campaign_id) => {
    //const token = localStorage.getItem('token');
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/walkbook/all/?campaign_id=` + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
          //headers: {'Authorization': `Bearer ${token}`}
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        // localStorage.setItem('walkbooks', JSON.stringify(data));
        
        return data;
      }
      else {
        throw new Error('ID or Name was not provided');
      }
    } else {
      console.error("No token found");
    }
  };



  getAllWalkbookTouchesForWeek = async (token, campaign_id, week) => {
    // const token = localStorage.getItem('firebase_token');
    if (!token) throw new Error("No token found");
  
    if (!campaign_id) throw new Error('Campaign ID was not provided');
    if (!['current-week', 'previous-week'].includes(week)) throw new Error('Invalid week parameter');

    // console.log('endpoint requesting: ', `${this.baseURL}/walkbook/touches/${week}/?campaign_id=` + campaign_id);
  
    const request = new Request(`${this.baseURL}/walkbook/touches/${week}/?campaign_id=` + campaign_id, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-client_type': 'web'
      }
    });
  
    const response = await fetch(request);
    const data = await response.json();
    return data;
  };



  getAllUserWalkbooks = async (token, user_id, campaign_id) => {
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (user_id && campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/walkbook/all-user/?user_id=`+user_id+'&campaign_id=' + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        // localStorage.setItem('walkbooks', JSON.stringify(data));
        return data;
      }
      else {
        throw new Error('user ID or campaign ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };



  updateWalkbookVoter = async (token, voter) => {

    // console.log('updateVoter: ', voter); 

    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    // const formData = {
    //   "id": id,
    //   "voter_id": voter_id,
    //   "old_status": old_status,
    //   "touch_result": touch_result,
    //   "voters_pending": voters_pending,
    //   "campaign_id": campaign_id,
    //   "user_id": user_id,
    // };

    // voter.voters_pending = voters_pending;

    if (token) {
      // if (id) {
      if (voter) {
        // Create request
        const request = new Request(`${this.baseURL}/walkbook/`, {
          method: 'PUT',
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          },
          // body: JSON.stringify(formData),
          body: JSON.stringify(voter),
          credentials: 'include',
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        // console.log(data);
        return data;
      }
      else {
        throw new Error('ID or Name was not provided');
      }
    } else {
      console.error("No token found");
    }
  };



  archiveWalkbook = async (token, walkbookId, campaignId) => {
    if (token) {
      if (walkbookId && campaignId) {
        const request = new Request(`${this.baseURL}/walkbook/archive/?walkbook_id=`+walkbookId+'&campaign_id=' + campaignId, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          },
        });

        const response = await fetch(request);
        const data = await response.json();
        return data;
      } else {
        throw new Error('Walkbook ID or campaign ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };

  deleteWalkbook = async (token, walkbookId, campaignId) => {
    if (token) {
      if (walkbookId && campaignId) {
        const request = new Request(`${this.baseURL}/walkbook/delete/?walkbook_id=`+walkbookId+'&campaign_id=' + campaignId, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          },
        });

        const response = await fetch(request);
        const data = await response.json();
        return data;
      } else {
        throw new Error('Walkbook ID or campaign ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };



}

export default new Walkbook();