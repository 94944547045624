import React, { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// react-bootstrap components
import {
    Alert,
    Badge,
    Button,
    Card,
    Form,
    FloatingLabel,
    Media,
    Navbar,
    Nav,
    Container,
    Col,
    Row
} from "react-bootstrap";


import authAPI from '../../apis/auth/auth';
import invitationAPI from "../../apis/invitation/invitation";
import campaignAPI from '../../apis/campaign/campaign';
import { useUserContext } from "../../contexts/UserContext";

const nameValidation = (value) =>
    /^[a-zA-Z]*$/.test(
        value
    );

const emailValidation = (value) =>
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        value
    );

const passwordSpecialCharacterValidation = (value) =>
    /^.*[.!#$%&*+=?@].*$/.test(
        value
    );

const passwordNumberValidation = (value) =>
    /^.*[0-9].*$/.test(
        value
    );

const equalTo = (value1, value2) => value1 === value2;
const isRequired = (value) => value !== null && value !== "" && value;
const isNumber = (value) => !isNaN(value) && value !== "";
const minLength = (value, length) => value.length >= length;
const maxLength = (value, length) => value.length <= length && value !== "";
const range = (value, min, max) => min <= value && value <= max;
const minValue = (value, min) => min <= value;
const maxValue = (value, max) => max >= value;




function useQuery() {
    return new URLSearchParams(useLocation().search);
}



function RegisterPage() {

    const {
        currentUser,
        setUser,
        requiresRegistration,
        setRequiresRegistration,
    } = useUserContext();

    const history = useHistory();
    const isMounted = useRef(false);

    const [first, setFirst] = useState('');
    const [firstState, setFirstState] = useState(false);

    const [last, setLast] = useState('');
    const [lastState, setLastState] = useState(false);

    const [email, setEmail] = useState('');
    // const [email, setEmail] = useState(currentUser && currentUser?.email ? currentUser?.email : currentUser?.uid);
    const [emailState, setEmailState] = useState(false);

    const [tempPassword, setTempPassword] = useState(''); // For the temporary password
    const [tempPasswordState, setTempPasswordState] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordState, setPasswordState] = useState(false);

    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [passwordConfirmationState, setPasswordConfirmationState] = useState(false);

    const [error, setError] = useState('');


    const query = useQuery();
    const [invitationToken, setInvitationToken] = useState("");

    useEffect(() => {
        const token = query.get("invitationToken");
        if (token) {
            setInvitationToken(token);
        } else {

            console.log('RegisterPage: Redirecting to /');

            history.push('/auth/login'); // Redirect to login if no current user or token is expired or email is not verified
        }
    }, [query]);


    useEffect(() => {

        let isMounted = true;

        const fetchInvitation = async () => {
            try {
                // const firebaseToken = await currentUser.getIdToken();
                const invitedEmail = await invitationAPI.validateInvitationToken(invitationToken);
                // console.log('RegisterPage: invitedEmail', invitedEmail);
                if (invitedEmail) {
                    if (isMounted) {
                        setEmail(invitedEmail);
                        setEmailState(true); // Assume validation passes since it's from the invitation
                    }
                } else {
                    // console.log('RegisterPage: Redirecting to /');

                    history.push('/auth/login'); // Redirect to login if no current user or token is expired or email is not verified
                }
            }
            catch (err) {
                console.error(err);
                if (err instanceof Error) {
                    // Handle errors thrown from frontend
                    setError(err.message);
                }
                else {
                    // Handle errors thrown from backend
                    if (err === 'INVALID_INVITATION_TOKEN') {
                        setError('Invalid invitation token');
                    }
                    else {
                        setError('Error occured while validating invitation token');
                    }
                }
                // console.log('RegisterPage: Redirecting to /');

                history.push('/auth/login'); // Redirect to login if no current user or token is expired or email is not verified
            }
        };


        if (invitationToken) {

            fetchInvitation();

        }

        return () => {
            isMounted = false;
        };

    }, [invitationToken]);



    useEffect(() => {
        // Component did mount logic
        isMounted.current = true;

        return () => {
            // Component will unmount logic
            isMounted.current = false;
        };
    }, []);

    // Function to call submit
    const callSubmit = async (e) => {

        // Prevents page reload on wrongs creds
        e.preventDefault();
        if (!isMounted.current) return; // Immediately return if component is not mounted
        setError('');

        try {
            if (isMounted.current) {

                // const user = await authAPI.signInAndUpdatePassword(email, tempPassword, password);
                const user = await authAPI.signUpWithEmail(email, password);
                console.log('RegisterPage: user', user);

                // Executes only when there are no 400 and 500 errors, else they are thrown as errors
                // Callbacks can be added here
                if (user) {

                    const firebaseToken = await user.getIdToken();
                    // Create user in backend
                    const data = await authAPI.register(firebaseToken, first, last, email, password, passwordConfirmation, user);


                    const userResult = await authAPI.getUser(firebaseToken);
                    // console.log("userResult: ", userResult);

                    if (userResult) {
                        if (isMounted.current) {
                            setUser(userResult);

                            history.push('/auth/verify',
                                {
                                    email: email,
                                    user: userResult
                                }
                            );
                        }

                    }

                }
            }
        }
        catch (err) {
            if (isMounted.current) {
                if (err instanceof Error) {
                    // Handle errors thrown from frontend
                    setError(err.message);
                }
                else {
                    // Handle errors thrown from backend
                    if (err === 'REGISTER_USER_ALREADY_EXISTS') {
                        setError('Email address is already associated with an account. Please use a different email address.');
                    }
                    else {
                        setError('Error occured while registering.');
                    }
                }
            }
        }
    };

    // const [cardClasses, setCardClasses] = useState("");

    const [cardClasses, setCardClasses] = useState("card-hidden");

    useEffect(() => {
      const timer = setTimeout(() => {
        setCardClasses("");
      }, 100);

      return () => clearTimeout(timer);
    }, []);


    return (
        <>
            <div className="content d-flex justify-content-center align-items-center p-0">
                <Container>
                    <Col className="mx-auto" lg="8" md="10">
                        {/* <Form onSubmit={callSubmit} action="" className="form" method=""> */}
                        <Form action="" className="form" method="">
                            <Card className={"card-login " + cardClasses} style={{ paddingBottom: '10px' }}>
                                <Card.Header>
                                    <h3 className="header text-center">Sign Up</h3>
                                </Card.Header>
                                {/* <Card.Body className="align-items-center" style={{ borderBottom: 'solid rgba(185, 185, 185, 0.521) 2px' }}> */}
                                <Card.Body className="align-items-center">
                                    <Row className="justify-content-center">

                                        <Col md={4} lg={4} className="text-center">
                                            <Form.Group
                                                className={
                                                    "mb-3 has-label " +
                                                    (firstState ? "has-success" : "has-error")
                                                }
                                            >
                                                <label>
                                                    First Name <span className="star">*</span>
                                                </label>
                                                <Form.Control
                                                    // placeholder="John"
                                                    type="text"
                                                    value={first}
                                                    onChange={(e) => {
                                                        setFirst(e.target.value);
                                                        if (nameValidation(e.target.value)) {
                                                            setFirstState(true);
                                                        } else {
                                                            setFirstState(false);
                                                        }
                                                    }}
                                                />
                                                {/* {firstState ? null : (
                                                    <label className="error">This field is required.</label>
                                                )} */}
                                            </Form.Group>
                                        </Col>

                                        <Col md={4} lg={4} className="text-center">
                                            <Form.Group
                                                className={
                                                    "mb-3 has-label " +
                                                    (lastState ? "has-success" : "has-error")
                                                }
                                            >
                                                <label>
                                                    Last Name <span className="star">*</span>
                                                </label>
                                                <Form.Control
                                                    // placeholder="Doe"
                                                    type="text"
                                                    value={last}
                                                    onChange={(e) => {
                                                        setLast(e.target.value);
                                                        if (nameValidation(e.target.value)) {
                                                            setLastState(true);
                                                        } else {
                                                            setLastState(false);
                                                        }
                                                    }}
                                                />
                                                {/* {lastState ? null : (
                                                    <label className="error">This field is required.</label>
                                                )} */}
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    <Row className="justify-content-center">
                                        <Col md={8} lg={8} className="text-center">

                                            <Form.Group
                                                className={
                                                    "mb-3 has-label " +
                                                    (emailState ? "has-success" : "has-error")
                                                }
                                            >
                                                <label>
                                                    Email Address <span className="star">*</span>
                                                </label>
                                                <Form.Control
                                                    placeholder="name@example.com"
                                                    type="email"
                                                    value={email}
                                                    disabled={true}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value);
                                                        if (emailValidation(e.target.value)) {
                                                            setEmailState(true);
                                                        } else {
                                                            setEmailState(false);
                                                        }
                                                    }}
                                                />
                                                {/* {emailState ? null : (
                                                    <label className="error">This field is required.</label>
                                                )} */}
                                            </Form.Group>
                                        </Col>
                                    </Row>


                                    <Row className="justify-content-center">
                                        <Col md={8} lg={8} className="text-center">
                                            <Form.Group
                                                className={
                                                    "mb-3 has-label " +
                                                    (passwordState ? "has-success" : "has-error")
                                                }
                                            >
                                                <label>
                                                    New Password <span className="star">*</span>
                                                </label>
                                                <Form.Control
                                                    // placeholder="Password"
                                                    type="password"
                                                    value={password}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                        if ((minLength(e.target.value, 10) && passwordNumberValidation(e.target.value)) && passwordSpecialCharacterValidation(e.target.value)) {
                                                            setPasswordState(true);
                                                        } else {
                                                            setPasswordState(false);
                                                        }
                                                    }}
                                                />

                                                {passwordState && (
                                                    <p style={{ textAlign: 'start', fontSize: '12px', color: '#9A9A9A' }}>
                                                        Must have a length of 10 or more, and contain a number, and a special character: !, ., @, $, %, #, {"&"}, ?, *, +, =
                                                    </p>
                                                )}
                                                {/* {passwordState ? (
                                                    <p style={{ textAlign: 'start', fontSize: '12px', color: '#9A9A9A' }}>
                                                        Must have a length of 10 or more, and contain a number, and a special character: !, ., @, $, %, #, {"&"}, ?, *, +, =
                                                    </p>
                                                ) : (
                                                    <p className="error" style={{ textAlign: 'start', fontSize: '12px', color: '#9A9A9A' }}>This field is required and it must have a length of 10 or more,
                                                        and contain a number, and a special character: !, ., @, $, %, #, {"&"}, ?, *, +, =
                                                    </p>
                                                )} */}

                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    <Row className="justify-content-center">

                                        <Col md={8} lg={8} className="text-center">

                                            <Form.Group
                                                className={
                                                    "mb-3 has-label " +
                                                    (passwordConfirmationState ? "has-success" : "has-error")
                                                }
                                            >
                                                <label>
                                                    New Password Confirmation <span className="star">*</span>
                                                </label>
                                                <Form.Control
                                                    // placeholder="Password Confirmation"
                                                    type="password"
                                                    value={passwordConfirmation}
                                                    onChange={(e) => {
                                                        setPasswordConfirmation(e.target.value);
                                                        if (equalTo(e.target.value, password)) {
                                                            setPasswordConfirmationState(true);
                                                        } else {
                                                            setPasswordConfirmationState(false);
                                                        }
                                                    }}
                                                />
                                                {passwordConfirmationState ? null : (
                                                    <label className="error">This field is required and needs to be equal with the
                                                        one above.</label>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-center" style={{ paddingBottom: '25px' }}>

                                        <Col md={8} lg={8} className="text-start">
                                            <div className="card-category form-category">
                                                <span className="star">*</span>
                                                Required fields
                                            </div>
                                            <Alert color='danger' style={error !== '' ? { display: "block" } : { display: "none" }}>
                                                {error}
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center justify-content-center">
                                        <Button
                                            className="btn-wd login-btn"
                                            onClick={(e) => {
                                                if (
                                                    !firstState ||
                                                    !nameValidation(first)
                                                ) {
                                                    setFirstState(false);
                                                } else {
                                                    setFirstState(true);
                                                }
                                                if (
                                                    !lastState ||
                                                    !nameValidation(last)
                                                ) {
                                                    setLastState(false);
                                                } else {
                                                    setLastState(true);
                                                }
                                                if (
                                                    !emailState ||
                                                    !emailValidation(email)
                                                ) {
                                                    setEmailState(false);
                                                } else {
                                                    setEmailState(true);
                                                }
                                                // if (
                                                //     !tempPasswordState ||
                                                //     !(minLength(tempPassword, 1))
                                                // ) {
                                                //     setTempPasswordState(false);
                                                // } else {
                                                //     setTempPasswordState(true);
                                                // }
                                                if (
                                                    !passwordState ||
                                                    !((minLength(password, 10) && passwordNumberValidation(password)) && passwordSpecialCharacterValidation(password))
                                                ) {
                                                    setPasswordState(false);
                                                } else {
                                                    setPasswordState(true);
                                                }
                                                if (
                                                    !passwordConfirmationState ||
                                                    !((minLength(passwordConfirmation, 10) && passwordNumberValidation(passwordConfirmation)) && passwordSpecialCharacterValidation(passwordConfirmation)) ||
                                                    !equalTo(passwordConfirmation, password)
                                                ) {
                                                    setPasswordConfirmationState(false);
                                                } else {
                                                    setPasswordConfirmationState(true);
                                                }
                                                if (firstState && lastState && emailState && passwordState && passwordConfirmationState) {
                                                    callSubmit(e);
                                                }
                                            }}
                                        >
                                            Sign Up
                                        </Button>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Form>
                    </Col>

                </Container>
            </div>
        </>
    );
}

export default RegisterPage;

