// import React, { useEffect } from 'react';

// const TermsAndConditions = () => {
//     useEffect(() => {
//         const script = document.createElement('script');
//         script.id = 'termly-jssdk';
//         script.src = 'https://app.termly.io/embed-policy.min.js';
//         script.async = true;

//         const tjs = document.getElementsByTagName('script')[0];
//         tjs.parentNode.insertBefore(script, tjs);

//         return () => {
//             if (document.getElementById('termly-jssdk')) {
//                 document.getElementById('termly-jssdk').remove();
//             }
//         };
//     }, []);

//     return <div name="termly-embed" data-id="2423e978-9598-4b17-bcfc-fbab79480eba"></div>;
// };

// export default TermsAndConditions;



import React from 'react';

const TermsAndConditions = () => {
    return (
        <iframe
            src="https://app.termly.io/policy-viewer/policy.html?policyUUID=2423e978-9598-4b17-bcfc-fbab79480eba"
            width="100%"
            height="100%"
            style={{ border: 'none' }}
            title="Terms and Conditions"
        />
    );
};

export default TermsAndConditions;
