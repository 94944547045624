import React, { useRef, useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

// react-bootstrap components
import {
    Alert,
    Button,
    Card,
    Form,
    FloatingLabel,
    Container,
    Col,
    Row
} from "react-bootstrap";


import authAPI from '../../apis/auth/auth';
import campaignAPI from '../../apis/campaign/campaign';
import walkbookAPI from '../../apis/walkbook/walkbook';
import roadSignAPI from '../../apis/road_sign/road_sign';
import recommendedWalkbookAPI from '../../apis/walkbook/recommended_walkbook';
import voterGroupAPI from '../../apis/voter_group/voter_group';
import voterTagsAPI from '../../apis/voter_tags/voter_tags';
import textCampaignAPI from '../../apis/text_campaign/text_campaign';
import invitationAPI from '../../apis/invitation/invitation';
// import noteBoardAPI from '../../apis/board/note_board';


import LoadingSpinner from "../../components/Spinner/LoadingSpinner";
import CampaignInvitationSelectionModal from "../../components/Login/CampaignInvitationSelectionModal";
import { useUserContext } from "../../contexts/UserContext";
import { auth } from "../../contexts/Firebase";
import { set } from "lodash";


function CampaignInvitationSelectPage() {

    const {
        campaignUser,
        setCampaignUser,
        currentUser,
        user,
        setUser,
        verified,
        setVerified,
        loading,
        setLoading,
        authenticated,
        setAuthenticated,
        setInvitation,
        setCampaign,
        setBoards,
        setWalkbooks,
        setRoadSigns,
        setRecommendedWalkbooks,
        setRecommendations,
        setVoterGroups,
        setVoterTags,
        setTextCampaigns,
        setLoadingCampaign,
        setLoadingWalkbooks,
        setLoadingRoadSigns,
        setLoadingVoterGroups,
        setLoadingRecommendations,
        setLoadingTextCampaigns,
    } = useUserContext();

    const history = useHistory();

    // const [showCampaignInvitationSelection, setShowCampaignInvitationSelection] = useState(false);
    const [userResult, setUserResult] = useState(null); //history.location.state.userResult);
    

    const [error, setError] = useState('');

    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleCampaignSelect = async (selectedIndex) => {


        // console.log("Handling campaign selection for index:", selectedIndex);
        if (!userResult || selectedIndex === null) return;

        const selectedCampaign = userResult.campaigns[parseInt(selectedIndex, 10)];
        if (!selectedCampaign) return;

        // console.log("Selected campaign: ", selectedCampaign);   

        // const updatedUserResult = {
        //   ...userResult,
        //   title: campaign.title,
        //   campaign_id: campaign.campaign_id,
        //   date_joined: campaign.date_joined,
        // };

        // const campaignUser = {
        //     ...userResult,
        //     title: campaign.title,
        //     campaign_id: campaign.campaign_id,
        //     date_joined: campaign.date_joined,
        // };
        
        const userData = {
            ...userResult,
            title: selectedCampaign.title,
            campaign_id: selectedCampaign.campaign_id,
            date_joined: selectedCampaign.date_joined,
        };

        const campaignUserData = {
            title: selectedCampaign.title,
            campaign_id: selectedCampaign.campaign_id,
            date_joined: selectedCampaign.date_joined,
        };


        // setShowCampaignInvitationSelection(false);

        if (isMounted.current) {
            setLoading("Loading data...");
            setLoadingCampaign("Loading campaign...");
            setLoadingWalkbooks("Loading walkbooks...");
            setLoadingRoadSigns("Loading road signs...");
            setLoadingVoterGroups("Loading voter groups...");
            setLoadingRecommendations("Loading recommendations...");
            setLoadingTextCampaigns("Loading text campaigns...");
        }

        try {
            // Fetch campaign-specific data

            // const campaignUser = await authAPI.getCampaignUser(campaign.campaign_id);

            if (campaignUserData) {
                const firebaseToken = await currentUser.getIdToken();
                const campaignResult = await campaignAPI.getCampaign(firebaseToken, campaignUserData.campaign_id);




                // Based on user title, fetch more data
                if (campaignUserData.title === 'Manager' || campaignUserData.title === 'Director') {
                    const walkbooksResult = await walkbookAPI.getAllWalkbooks(firebaseToken, campaignUserData.campaign_id);
                    const roadSignsResult = await roadSignAPI.getAllRoadSigns(firebaseToken, campaignUserData.campaign_id);
                    const recommendationsResult = await recommendedWalkbookAPI.getAllRecommendations(firebaseToken, campaignUserData.campaign_id);
                    const voterGroupsResult = await voterGroupAPI.getAllVoterGroups(firebaseToken, campaignUserData.campaign_id);
                    const voterTagsResult = await voterTagsAPI.getAllTags(firebaseToken);
                    const textCampaignsResult = await textCampaignAPI.getAllTextCampaigns(firebaseToken, campaignUserData.campaign_id);

                    // Update the global state with the fetched data
                    if (isMounted.current) {
                        setCampaignUser(campaignUserData);
                        setUser(userData);
                        setCampaign(campaignResult);
                        setLoadingCampaign("");
                        setWalkbooks({ type: 'REPLACE', newWalkbooks: walkbooksResult });
                        setLoadingWalkbooks("");
                        setRoadSigns({ type: 'REPLACE', newRoadSigns: roadSignsResult });
                        setLoadingRoadSigns("");
                        setRecommendations({ type: 'REPLACE', newRecommendations: recommendationsResult });
                        setLoadingRecommendations("");
                        setVoterGroups({ type: 'REPLACE', newVoterGroups: voterGroupsResult });
                        setLoadingVoterGroups("");
                        setVoterTags({ type: 'REPLACE', newVoterTags: voterTagsResult });
                        setTextCampaigns({ type: 'REPLACE', newTextCampaigns: textCampaignsResult });
                        setLoadingTextCampaigns("");
                        setLoading("");

                    }
                    window.history.replaceState(null, null, '/');
                    history.push('/turrim/dashboard');

                } else { // if (campaignUser.title === 'Staff' || campaignUser.title === 'Volunteer') {

                    const walkbooksResult = await walkbookAPI.getAllUserWalkbooks(firebaseToken, userData.id, campaignUserData.campaign_id);
                    const roadSignsResult = await roadSignAPI.getAllRoadSigns(firebaseToken, campaignUserData.campaign_id);
                    const voterTagsResult = await voterTagsAPI.getAllTags(firebaseToken);
                    const textCampaignsResult = await textCampaignAPI.getAllUserTextCampaigns(firebaseToken, userData.id, campaignUserData.campaign_id);
                    // Update the global state with the fetched data
                    if (isMounted.current) {
                        setCampaignUser(campaignUserData);
                        setUser(userData);
                        setCampaign(campaignResult);
                        setLoadingCampaign("");
                        setWalkbooks({ type: 'REPLACE', newWalkbooks: walkbooksResult });
                        setLoadingWalkbooks("");
                        setRoadSigns({ type: 'REPLACE', newRoadSigns: roadSignsResult });
                        setLoadingRoadSigns("");
                        setVoterTags({ type: 'REPLACE', newVoterTags: voterTagsResult });
                        setTextCampaigns({ type: 'REPLACE', newTextCampaigns: textCampaignsResult });
                        setLoadingTextCampaigns("");
                        setLoading("");

                    }
                    window.history.replaceState(null, null, '/');
                    history.push('/turrim/walkbooks');
                }

                // window.history.replaceState(null, null, '/');
                // history.push('/');
            }



        } catch (error) {
            console.error("Error loading campaign-specific data:", error);
            if (isMounted.current) {
                setLoading("");
                setError("Failed to load campaign data. Please try again.");
            }

        }

    };


    const handleInvitationSelect = async (selectedIndex) => {
        // console.log("Handling Invitation selection for index:", selectedIndex);
        if (!userResult || selectedIndex === null) return;

        const selectedInvitation = userResult.invitations[parseInt(selectedIndex, 10)];
        // console.log("invitation: ", invitation);
        if (!selectedInvitation) return;

        const updatedUserResult = {
            ...userResult,
            title: 'None',
            campaign_id: null,
            date_joined: null,
        };

        const campaignUserData = {
            invitation_title: selectedInvitation.offer_title,
            invitation_campaign_id: selectedInvitation.campaign_id,
            invitation_date_joined: null,
        };

        // Remove the invitations property as it's no longer needed
        // delete updatedUserResult.invitations;

        // setShowCampaignInvitationSelection(false);

        // console.log("Invitation selected: ", selectedInvitation);

        if (isMounted.current) {
            setLoading("Loading data...");
            // setCampaignUser(campaignUserData);
            setUser(updatedUserResult);
            setInvitation(selectedInvitation);
            setLoading("");
        }

        window.history.replaceState(null, null, '/');
        history.push('/turrim/invitation');
        // history.push('/turrim/campaign-invitation');

    };


    const [cardClasses, setCardClasses] = useState("card-hidden");


    useEffect(() => {
        const timer = setTimeout(() => {
            setCardClasses("");
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    // console.log("Rendering, userResult:", userResult);

    useEffect(() => {

        let isMounted = true; // Flag to track the mounted state of the component

        const fetchData = async () => {
            const firebaseToken = await currentUser.getIdToken();
            const userData = await authAPI.getUser(firebaseToken);
            if (isMounted) {
                setUserResult(userData);
            }
        };

        if (currentUser) {
            // console.log('CampaignSelectPage: Fetching user data');
            fetchData();
        }
        else {
            console.log('CampaignSelectPage: Redirecting to /');
            console.log('CampaignSelect: currentUser', currentUser);

            history.push('/'); // Redirect to login if no current user or token is expired or email is not verified
        }

        return () => {
            isMounted = false; // Set the flag to false when the component unmounts
        };

    }, [history, currentUser]);

    return (
        <>
            <div className="content d-flex align-items-center p-0">
                <Container>


                    {((loading !== null) && (loading !== "")) ? (
                        <LoadingSpinner />
                    ) : (
                        userResult && (
                            <CampaignInvitationSelectionModal
                                campaigns={userResult?.campaigns}
                                onSelect={handleCampaignSelect}
                                invitations={userResult?.invitations}
                                onSelectInvitation={handleInvitationSelect}
                            />
                        )
                    )}
                </Container>
            </div>
        </>
    );
}

export default CampaignInvitationSelectPage;