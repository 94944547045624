// import React, { useEffect } from 'react';

// const PrivacyPolicy = () => {

//     useEffect(() => {
//         const script = document.createElement('script');
//         script.id = 'termly-jssdk';
//         script.src = 'https://app.termly.io/embed-policy.min.js';
//         script.async = true;

//         const tjs = document.getElementsByTagName('script')[0];
//         tjs.parentNode.insertBefore(script, tjs);

//         return () => {
//             if (document.getElementById('termly-jssdk')) {
//                 document.getElementById('termly-jssdk').remove();
//             }
//         };
//     }, []);

//     return <div name="termly-embed" data-id="aa96068e-8291-4c6d-ad4f-ff7981521fe8"></div>;
// };

// export default PrivacyPolicy;




import React from 'react';

const PrivacyPolicy = () => {
    return (
        <iframe
            src="https://app.termly.io/policy-viewer/policy.html?policyUUID=aa96068e-8291-4c6d-ad4f-ff7981521fe8"
            width="100%"
            height="100%"
            style={{ border: 'none' }}
            title="Privacy Policy"
        ></iframe>
    );
};

export default PrivacyPolicy;

