import React, { useEffect, useState } from 'react';
import { Switch, Redirect, Route } from "react-router-dom";

import SidebarContext from "./contexts/SidebarContext";
import { UserProvider } from "./contexts/UserContext";
import DataLoader from "./contexts/DataLoader.js";

import ProtectedRoute from "./apis/auth/protected-route.js";

import AuthLayout from "./layouts/Auth.js";
import TurrimLayout from "./layouts/TurrimLayout.js";

import ScrollToTop from "./components/Main/ScrollToTop";


function App() {

  const [isSidebarMini, setIsSidebarMini] = useState(document.body.classList.contains('sidebar-mini'));
 
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setIsSidebarMini(true);
        document.body.classList.add('sidebar-mini');
      } else {
        setIsSidebarMini(false);
        document.body.classList.remove('sidebar-mini');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const toggleSidebar = () => {
    setIsSidebarMini(!isSidebarMini);
    document.body.classList.toggle('sidebar-mini');
  };


  return (
    <>
      
        <ScrollToTop />
        <SidebarContext.Provider value={{ isSidebarMini, toggleSidebar }}>
          <UserProvider>
            
            <DataLoader />
            <Switch>

              {/* Add a route to directly serve the AASA file */}
              {/* <Route path="/.well-known/apple-app-site-association" exact>
                <div></div>
              </Route> */}

              <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
              <ProtectedRoute path="/turrim" render={(props) => <TurrimLayout {...props} />} />
              <Redirect from="/" to="/turrim" />

            </Switch>
          </UserProvider>
        </SidebarContext.Provider>

    </>
  );
}

export default App;


// import React, { useEffect, useState } from 'react';
// import { Switch, Redirect, Route } from "react-router-dom";

// import SidebarContext from "./contexts/SidebarContext";
// import { UserProvider } from "./contexts/UserContext";
// import DataLoader from "./contexts/DataLoader.js";

// import ProtectedRoute from "./apis/auth/protected-route.js";

// import AuthLayout from "./layouts/Auth.js";
// import TurrimLayout from "./layouts/TurrimLayout.js";

// import ScrollToTop from "./components/Main/ScrollToTop";


// function App() {

//   const [isSidebarMini, setIsSidebarMini] = useState(document.body.classList.contains('sidebar-mini'));

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth < 992) {
//         setIsSidebarMini(true);
//         document.body.classList.add('sidebar-mini');
//       } else {
//         setIsSidebarMini(false);
//         document.body.classList.remove('sidebar-mini');
//       }
//     };

//     handleResize();

//     window.addEventListener('resize', handleResize);

//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);


//   const toggleSidebar = () => {
//     setIsSidebarMini(!isSidebarMini);
//     document.body.classList.toggle('sidebar-mini');
//   };


//   return (
//     <>
//       <ScrollToTop />
//       <SidebarContext.Provider value={{ isSidebarMini, toggleSidebar }}>
//         <UserProvider>
//           <DataLoader />
//           <Switch>
//             {/* Add a route to directly serve the AASA file */}
//             {/* <Route path="/.well-known/apple-app-site-association" exact>
//               <div></div>
//             </Route> */}

//             <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
//             <ProtectedRoute path="/turrim" render={(props) => <TurrimLayout {...props} />} />
//             <Redirect from="/" to="/turrim" />
//           </Switch>
//         </UserProvider>
//       </SidebarContext.Provider>
//     </>
//   );
// }

// export default App;
