import React from "react";
import ReactDOM from "react-dom";
// import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/turrim-app.min.css";
import './assets/css/app.css';
import "./assets/css/turrim.css";
import './assets/css/map_styles.css';
import "./assets/css/ErrorMessage.css";
import "./assets/css/ElectionCard.css";


import App from './App';

ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  document.getElementById("root")
);

// ReactDOM.createRoot(document.getElementById('root')).render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// );