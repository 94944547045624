import React from 'react';
// import './ErrorMessage.css'; // Import the CSS file

const ErrorMessage = ({ errorMessage }) => {
  return (
    <div className="error-container">
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export default ErrorMessage;
