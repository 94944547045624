import React, { Suspense, useContext, useState, useEffect, useCallback, useRef } from "react";
// import { useHistory } from 'react-router';
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";

import { Spinner, Container, Row, Col, Card } from "react-bootstrap";


// core components
import Sidebar from "../components/Sidebar/Sidebar.js";
import TurrimNavbar from "../components/Navbars/TurrimNavbar.js";
import AuthNavbar from "../components/Navbars/AuthNavbar.js";

import CampaignInvitationSelectPage from "../views/Auth/CampaignInvitationSelectPage.js";
import UserTermsPage from "../views/Auth/UserTermsPage.js";

import { managerDirectorRoutes, staffVolunteerRoutes } from "../routes.js";

import LoadingSpinner from "../components/Spinner/LoadingSpinner";
import LocalLoadingSpinner from "../components/Spinner/LocalLoadingSpinner.js";
import URLNotFound from "../components/NotFound/URLNotFound.js";


import authAPI from '../apis/auth/auth';
import { useUserContext } from "../contexts/UserContext";
import SidebarContext from "../contexts/SidebarContext";
import { load } from "@loaders.gl/core";


// import JoinCampaign from "../views/Campaign/JoinCampaign.js";
const JoinCampaign = React.lazy(() => import("../views/Campaign/JoinCampaign.js"));

const WalkbookAdd = React.lazy(() => import("../views/Walkbook/WalkbookAdd"));
const WalkbookView = React.lazy(() => import("../views/Walkbook/WalkbookView"));
const RoadSignAdd = React.lazy(() => import("../views/RoadSign/RoadSignAdd"));
const RoadSignView = React.lazy(() => import("../views/RoadSign/RoadSignView"));
const RoadSignPlacement = React.lazy(() => import("../views/RoadSign/RoadSignPlacement"));
const RecommendedWalkbookRequest = React.lazy(() => import("../views/Recommendations/Walkbook/RecommendedWalkbookRequest"));
const SimpleRecommendedWalkbookRequest = React.lazy(() => import("../views/Recommendations/Walkbook/SimpleRecommendedWalkbookRequest"));
const RecommendationView = React.lazy(() => import("../views/Recommendations/Walkbook/RecommendationView"));
const RecommendedWalkbookView = React.lazy(() => import("../views/Recommendations/Walkbook/RecommendedWalkbookView"));
const VoterSearch = React.lazy(() => import("../views/Voter/Search/VoterSearch"));
const VoterGroupView = React.lazy(() => import("../views/Voter/Group/VoterGroupView"));
const TextCampaignAdd = React.lazy(() => import("../views/TextCampaign/TextCampaignAdd"));
const TextCampaignView = React.lazy(() => import("../views/TextCampaign/TextCampaignView"));
const UserView = React.lazy(() => import("../views/User/UserView"));

function TurrimLayout() {
  const location = useLocation();

  const {
    campaignUser,
    setCampaignUser,
    authenticating,
    setAuthenticating,
    user,
    setUser,
    currentUser,
    setCurrentUser,
    loading,
    setLoading,
    setAuthenticated,
    verified,
    setVerified,
    invitation,
    setInvitation,
    campaign,
    setCampaign,
    setWalkbooks,
    setRoadSigns,
    setRecommendations,
    setVoterGroups,
    // setTextCampaigns,
    loadingCampaign,
    setLoadingCampaign,
    loadingWalkbooks,
    setLoadingWalkbooks,
    loadingRoadSigns,
    setLoadingRoadSigns,
    loadingRecommendations,
    setLoadingRecommendations,
    loadingVoterGroups,
    setLoadingVoterGroups
  } = useUserContext();
  const { toggleSidebar } = useContext(SidebarContext);

  const history = useHistory();
  const [, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  const [userResult, setUserResult] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [countdown, setCountdown] = useState(120);
  const [timerId, setTimerId] = useState(null);
  const [modalTimeoutId, setModalTimeoutId] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const isMounted = useRef(false);

  useEffect(() => {

    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);


  // useEffect(() => {
  //   if ((loading !== null && loading !== "") || (loadingCampaign !== null && loadingCampaign !== "") || (loadingWalkbooks !== null && loadingWalkbooks !== "") || (loadingRoadSigns !== null && loadingRoadSigns !== "") || (loadingRecommendations !== null && loadingRecommendations !== "") || (loadingVoterGroups !== null && loadingVoterGroups !== "")) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // }, [loading, loadingCampaign, loadingWalkbooks, loadingRoadSigns, loadingRecommendations, loadingVoterGroups]);


  // useEffect(() => {
  //   // Wait 2.5 seconds then check if the campaign is loaded
  //   const timer = setTimeout(() => {
  //     if (loadingCampaign === null || loadingCampaign === "") {
  //       setIsLoading(false);
  //     }
  //   }, 2500); // wait 2.5 seconds before considering the app as fully loaded

  //   return () => clearTimeout(timer);
  // }, [loadingCampaign]);

  useEffect(() => {
    // Simulate fetching data or waiting for some async process
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2500); // wait 2.5 seconds before considering the app as fully loaded

    return () => clearTimeout(timer);
  }, []);



  const clearLocalStorage = () => {
    // const items = ['invitation', 'walkbooks', 'roadSigns', 'recommendations', 'voterGroups', 'campaign', 'campaignUser', 'user', 'firebase_token'];
    const items = ['invitation', 'campaignUser'];
    items.forEach(item => localStorage.removeItem(item));
  };


  const callLogout = async () => {
    if (timerId) {
      clearInterval(timerId);

      //
      // setTimerId(null);
    }
    if (modalTimeoutId) {
      modalTimeoutId.forEach(clearTimeout);

      //
      // setModalTimeoutId(null);
    }
    setError('');
    setErrorMessage('');
    try {
      setLoading("")
      setLoadingCampaign("");
      setLoadingWalkbooks("");
      setLoadingRoadSigns("");
      setLoadingRecommendations("");
      setLoadingVoterGroups("");
      // setLoadingTextCampaigns("");
      setAuthenticating(true);
      setAuthenticated(false);
      setVerified(false);
      setCurrentUser(null);
      setUser({});
      setCampaignUser(null);
      setCampaign({});
      setInvitation(null);
      setWalkbooks([]);
      setRoadSigns([]);
      setRecommendations([]);
      setVoterGroups([]);
      // setTextCampaigns([]);
      clearLocalStorage();
      localStorage.clear();
      await authAPI.signOut();
    }
    catch (err) {
      console.error('Error logging out:', err);
    }
    finally {
      clearLocalStorage();
      localStorage.clear();
      history.push('/');
    }
  };


  // let currentRoutes = managerDirectorRoutes; // default to managerRoutes
  // if (campaignUser.title === 'Staff' || campaignUser.title === 'Volunteer') {
  //   currentRoutes = staffVolunteerRoutes;
  // }

  let currentRoutes = []; // default to no routes
  // let currentRoutes = staffVolunteerRoutes; // default to staffRoutes
  if (Object.keys(campaignUser).length > 0) {
    if (campaignUser.title === 'Staff' || campaignUser.title === 'Volunteer') {
      currentRoutes = staffVolunteerRoutes;
    }
    if (campaignUser.title === 'Manager' || campaignUser.title === 'Director') {
      currentRoutes = managerDirectorRoutes;
    }
  }


  const hasUserData = Object.keys(campaignUser).length > 0 || Object.keys(invitation).length > 0;
  const isUserUnacceptedTerms = Object.keys(user).length > 0 && !user.terms_accepted;
  const isNoActiveUserOrInvitation = Object.keys(campaignUser).length === 0 && Object.keys(invitation).length === 0;

  // Determine the routes for the sidebar based on user title
  const sidebarRoutes = campaignUser.title === 'Manager' || campaignUser.title === 'Director' ? managerDirectorRoutes :
    campaignUser.title === 'Staff' || campaignUser.title === 'Volunteer' ? staffVolunteerRoutes : [];

  // Setup class names based on user data
  const wrapperClass = hasUserData ? "wrapper main-wrapper" : "wrapper wrapper-full-page";




  const getRoutes = (routes) => {
    if (Object.keys(campaign).length > 0) {
      return routes.map((prop, key) => {
        if (prop.collapse) {
          return getRoutes(prop.views);
        }
        if (prop.layout === "/turrim") {
          return (
            <Route
              path={prop.layout + prop.path}
              key={key}
              component={prop.component}
            />
          );
        } else {
          return null;
        }
      });
    }
    else {
      return routes.map((prop, key) => {
        if (prop?.layout === "/turrim" && prop?.path === "/campaign") {
          return (
            <Route
              path={prop.layout + prop.path}
              key={key}
              component={prop.component}
            />
          );
        } else {
          return null;
        }
      });
    }
  };

  const getAllRoutes = () => {
    let returnRoutes;
    // console.log("Object.keys(user).length > 0 && !user.terms_accepted: ", Object.keys(user).length > 0 && !user.terms_accepted);
    // console.log("Object.keys(user).length > 0: ", Object.keys(user).length > 0);
    // console.log("user: ", user);
    // console.log("!user.terms_accepted: ", !user.terms_accepted);
    // console.log("(Object.keys(campaignUser).length === 0) && (Object.keys(invitation).length === 0): ", (Object.keys(campaignUser).length === 0) && (Object.keys(invitation).length === 0));

    if (Object.keys(user).length === 0) {
      // Return a loading component or null if data is still being loaded
      return (
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <Card className="card-login">
                <Card.Body>
                  {/* <h3 className="header text-center">{loading}</h3> */}
                  {" "}<br />
                  <Row className="justify-content-center">
                    <Spinner animation="border" />
                  </Row>
                  {" "}<br />
                </Card.Body>
                {/* <Card.Footer className="ml-auto mr-auto">
                </Card.Footer> */}
              </Card>
            </Col>
          </Container>
        </div>
      );
    }


    if (Object.keys(user).length > 0 && !user.terms_accepted) {
      // console.log("User has not accepted terms");
      return (
        // <Switch>
        //   <Route path="/turrim/terms" >
        //     <UserTermsPage />
        //   </Route>
        //   <Route path="*">
        //     <Redirect to="/turrim/terms" />
        //   </Route>
        // </Switch>

        <Switch>
          {/* <Route exact path="/turrim/terms" component={UserTermsPage} /> */}
          <Route exact path="/turrim/terms" >
            <UserTermsPage />
          </Route>
          <Route path="*" render={() => <Redirect to="/turrim/terms" />} />
        </Switch>

      );
    }
    else if ((Object.keys(campaignUser).length === 0) && (Object.keys(invitation).length === 0)) {
      // console.log("No active user or invitation");
      return (
        // <Switch>
        //   <Route path={"/turrim/user-options"}>
        //     <CampaignInvitationSelectPage />
        //   </Route>
        //   <Route path="*">
        //     <Redirect to="/turrim/user-options" />
        //   </Route>
        // </Switch>

        <Switch>
          <Route exact path="/turrim/user-options" component={CampaignInvitationSelectPage} />
          <Route path="*" render={() => <Redirect to="/turrim/user-options" />} />
        </Switch>
      );
    }
    else if (Object.keys(campaignUser).length > 0) {
      if (campaignUser.title === 'Manager' || campaignUser.title === 'Director') {
        // console.log("User is a Manager or Director");
        return (
          <Switch>
            {getRoutes(managerDirectorRoutes)}
            <Route path={"/turrim/walkbook/add"}>
              <WalkbookAdd />
            </Route>
            <Route path={"/turrim/walkbook/id/:id"}>
              <WalkbookView />
            </Route>
            <Route path={"/turrim/road-sign/add"}>
              <RoadSignAdd />
            </Route>
            <Route path={"/turrim/road-sign/id/:id"}>
              <RoadSignView />
            </Route>
            <Route path={"/turrim/road-sign-placement"}>
              <RoadSignPlacement />
            </Route>
            {/* <Route path={"/turrim/recommendation/request"}>
              <RecommendedWalkbookRequest />
            </Route> */}
            <Route path={"/turrim/recommendation/request"}>
              <SimpleRecommendedWalkbookRequest />
            </Route>
            <Route path={"/turrim/recommendation/id/:id"}>
              <RecommendationView />
            </Route>
            <Route path={"/turrim/recommendation-walkbook/:id"}>
              <RecommendedWalkbookView />
            </Route>
            <Route path={"/turrim/voter-group/search"}>
              <VoterSearch />
            </Route>
            <Route path={"/turrim/voter-group/id/:id"}>
              <VoterGroupView />
            </Route>
            <Route path={"/turrim/text-campaign/add"}>
              <TextCampaignAdd />
            </Route>
            <Route path={"/turrim/text-campaign/id/:id"}>
              <TextCampaignView />
            </Route>

            {/* <Route path={"/turrim/user/:name"}>
              <UserView />
            </Route> */}
            <Route path={"/turrim/user/:id"}>
              <UserView />
            </Route>



            {!isLoading && (
              <>
                {/* <Route path={"/turrim"}>
                  <Redirect to="/turrim/dashboard" />
                </Route> */}
                <Route path="*">
                  <URLNotFound loading_message={"The page you are looking for doesn't exist or has been moved."} />
                </Route>
              </>
            )}

          </Switch>
        );
      }
      // else {
      else if (campaignUser.title === 'Staff' || campaignUser.title === 'Volunteer') {
        // console.log("User is Staff or Volunteer");
        return (
          <Switch>
            {getRoutes(staffVolunteerRoutes)}
            <Route exact path={"/turrim/walkbook/id/:id"}>
              <WalkbookView />
            </Route>
            <Route exact path={"/turrim/road-sign/id/:id"}>
              <RoadSignView />
            </Route>
            <Route exact path={"/turrim/road-sign-placement"}>
              <RoadSignPlacement />
            </Route>
            {/* <Route exact path={"/turrim/text-campaign/id/:id"}>
              <TextCampaignView />
            </Route> */}

            {!isLoading && (
              <Route path="*">
                <URLNotFound loading_message={"The page you are looking for doesn't exist or has been moved."} />
              </Route>
            )}

          </Switch>
        );
      }

    }
    else if (Object.keys(invitation).length > 0) {
      // console.log("Invitation exists");
      return (
        <Switch>
          <Route exact path={"/turrim/invitation"}>
            <JoinCampaign />
          </Route>

          <Route path="*">
            <Redirect to="/turrim/invitation" />
          </Route>
        </Switch>
      );
    }

    // Optionally return a loading indicator route or null if not yet ready to show routes
    return null;

  };



  return (
    <>
      {(Object.keys(campaignUser).length > 0 || Object.keys(invitation).length > 0) ? (
        <>
          <div id="main-wrapper-scrollable-container" className="wrapper main-wrapper">
            <Sidebar routes={sidebarRoutes} />
            <div className="main-panel">
              <TurrimNavbar logout={callLogout} />
              <div className="content">
                <Suspense
                  fallback={
                    null
                    // <LocalLoadingSpinner loading_message="Loading..." />
                  }
                >
                  {getAllRoutes()}
                  {/* <Switch>
                    {getAllRoutes()}
                  </Switch> */}
                </Suspense>
                {((Object.keys(invitation).length === 0) && ((loadingCampaign !== null) && (loadingCampaign !== ""))) ? (
                  <Container
                    style={{ minHeight: 'calc(100vh - 130px)' }}
                  >
                    <LocalLoadingSpinner loading_message={"Reloading data"} />
                  </Container>
                ) : (
                  null
                )}
              </div>
              <div className="close-layer" onClick={() => {
                toggleSidebar();
                document.documentElement.classList.toggle("nav-open");
              }} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper wrapper-full-page">
            <div className="full-page section-image" data-color="black">
              <AuthNavbar logout={callLogout} />

              {getAllRoutes()}
              {/* <Switch>
                {getAllRoutes()}
              </Switch> */}

            </div>
          </div>
        </>
      )}
      {/* // )} */}
    </>
  );
}

export default TurrimLayout;




//   return (
//     <>
//       {((Object.keys(campaignUser).length > 0) || (Object.keys(invitation).length > 0)) ? (
//         <>
//           <div id="main-wrapper-scrollable-container" className="wrapper main-wrapper">
//             <Sidebar
//               routes={(campaignUser.title === 'Manager' || campaignUser.title === 'Director') ? managerDirectorRoutes : ((campaignUser.title === 'Staff' || campaignUser.title === 'Volunteer') ? staffVolunteerRoutes : [])}
//             />
//             <div
//               className="main-panel"
//             >
//               <TurrimNavbar
//                 logout={callLogout}
//               />
//               <div
//                 className="content"
//               >
//                 <Suspense
//                   fallback={
//                     <LocalLoadingSpinner
//                       loading_message="Loading..."
//                     />
//                   }
//                 >
//                   <Switch>
//                     {getAllRoutes()}
//                   </Switch>
//                 </Suspense>
//               </div>
//               <div
//                 className="close-layer"
//                 onClick={() => {
//                   console.log("toggleSidebar from close-layer click");
//                   toggleSidebar();
//                   document.documentElement.classList.toggle("nav-open");
//                 }}
//               />
//             </div>
//           </div >
//         </>
//       ) : (
//         <>
//           <div className="wrapper wrapper-full-page">
//             <div
//               className="full-page section-image"
//               data-color="black"
//             >
//               <AuthNavbar
//                 logout={callLogout}
//               />

//               <Switch>
//                 {(Object.keys(user).length > 0 && !user.terms_accepted) ? (
//                   <Route path={"/turrim/terms"}>
//                     <UserTermsPage />
//                   </Route>
//                 ) : (
//                   null
//                 )}

//                 {(Object.keys(campaignUser).length === 0) && (Object.keys(invitation).length === 0) ? (
//                   <Route path={"/turrim/user-options"}>
//                     <CampaignInvitationSelectPage />
//                   </Route>
//                 ) : (
//                   null
//                 )}

//                 <Redirect to={fallbackRoutePath()} />
//               </Switch>

//               {/* <Switch>
//                 {getAllRoutes()}
//               </Switch> */}
//             </div>
//           </div>
//         </>
//       )
//       }
//     </>
//   );
// }

// export default TurrimLayout;







//   return (
//     <>
//       {((Object.keys(campaignUser).length > 0) || (Object.keys(invitation).length > 0)) ? (
//         <>
//           <div id="main-wrapper-scrollable-container" className="wrapper main-wrapper">
//             <Sidebar
//               routes={(campaignUser.title === 'Manager' || campaignUser.title === 'Director') ? managerDirectorRoutes : ((campaignUser.title === 'Staff' || campaignUser.title === 'Volunteer') ? staffVolunteerRoutes : [])}
//             />
//             <div
//               className="main-panel"
//             >
//               <TurrimNavbar
//                 logout={callLogout}
//               />
//               <div
//                 className="content"
//               >
//                 {(Object.keys(invitation).length > 0) ? (
//                   <Suspense
//                     fallback={
//                       <LocalLoadingSpinner
//                         loading_message="Loading..."
//                       />
//                     }
//                   >
//                     <Switch>
//                       {/* {getRoutes(currentRoutes)} */}
//                       {/* <Route path={"/turrim/campaign-invitation"}>
//                         <JoinCampaign />
//                       </Route>
//                       <Redirect from="/turrim" to="/turrim/campaign-invitation" />
//                       <Redirect to="/turrim/campaign-invitation" /> */}

//                       {/* <Route exact path="/turrim/campaign-invitation">
//                         <JoinCampaign />
//                       </Route>
//                       <Route path="*">
//                         <Redirect to="/turrim/campaign-invitation" />
//                       </Route> */}

//                       <Route exact path="/turrim/campaign-invitation" component={JoinCampaign} />
//                       <Route path="*" render={() => <Redirect to="/turrim/campaign-invitation" />} />
//                     </Switch>
//                   </Suspense>
//                 ) : (
//                   (campaignUser.title === 'Manager' || campaignUser.title === 'Director') ? (
//                     <Suspense
//                       fallback={
//                         <LocalLoadingSpinner
//                           loading_message="Loading..."
//                         />
//                       }
//                     >

//                       <Switch>
//                         {getRoutes(managerDirectorRoutes)}
//                         <Route path={"/turrim/walkbook/add"}>
//                           <WalkbookAdd />
//                         </Route>
//                         <Route path={"/turrim/walkbook/:name"}>
//                           <WalkbookView />
//                         </Route>
//                         <Route path={"/turrim/road-sign/add"}>
//                           <RoadSignAdd />
//                         </Route>
//                         <Route path={"/turrim/road-sign/:name"}>
//                           <RoadSignView />
//                         </Route>
//                         <Route path={"/turrim/road-sign-placement"}>
//                           <RoadSignPlacement />
//                         </Route>
//                         <Route path={"/turrim/recommendation/request"}>
//                           <RecommendedWalkbookRequest />
//                         </Route>
//                         <Route path={"/turrim/recommendation/:name"}>
//                           <RecommendationView />
//                         </Route>
//                         <Route path={"/turrim/recommendation-walkbook/:id"}>
//                           <RecommendedWalkbookView />
//                         </Route>
//                         <Route path={"/turrim/voter-group/search"}>
//                           <VoterSearch />
//                         </Route>
//                         <Route path={"/turrim/voter-group/:name"}>
//                           <VoterGroupView />
//                         </Route>
//                         <Route path={"/turrim/user/:name"}>
//                           <UserView />
//                         </Route>

//                         <Redirect from="/turrim" to="/turrim/dashboard" />

//                       </Switch>
//                     </Suspense>
//                   ) : (
//                     <Suspense
//                       fallback={
//                         <LocalLoadingSpinner
//                           loading_message="Loading..."
//                         />
//                       }
//                     >
//                       <Switch>
//                         {getRoutes(staffVolunteerRoutes)}
//                         <Route path={"/turrim/walkbook/:name"}>
//                           <WalkbookView />
//                         </Route>
//                         <Route path={"/turrim/road-sign/:name"}>
//                           <RoadSignView />
//                         </Route>
//                         <Route path={"/turrim/road-sign-placement"}>
//                           <RoadSignPlacement />
//                         </Route>
//                         <Redirect from="/turrim" to="/turrim/walkbooks" />
//                       </Switch>
//                     </Suspense>
//                   )
//                 )}

//               </div>
//               <div
//                 className="close-layer"
//                 onClick={() => {
//                   console.log("toggleSidebar from close-layer click");
//                   toggleSidebar();
//                   document.documentElement.classList.toggle("nav-open");
//                 }}
//               />
//             </div>
//           </div >
//         </>
//       ) : (
//         <>
//           <div className="wrapper wrapper-full-page">
//             <div
//               className="full-page section-image"
//               data-color="black"
//             >
//               <AuthNavbar
//                 logout={callLogout}
//               />
//               {(Object.keys(user).length > 0) && !user.terms_accepted ? (
//                 <Switch>
//                   <Route path={"/turrim/terms"}>
//                     <UserTermsPage />
//                   </Route>
//                   <Redirect from="/turrim" to="/turrim/terms" />
//                 </Switch>
//               ) : (
//                 <Switch>

//                   <Route path={"/turrim/user-options"}>
//                     <CampaignInvitationSelectPage />
//                   </Route>
//                   <Redirect from="/turrim" to="/turrim/user-options" />
//                 </Switch>
//               )}
//             </div>
//           </div>
//         </>
//       )
//       }
//     </>
//   );
// }

// export default TurrimLayout;












//   return (
//     <>
//       {/* <div className="wrapper"> */}
//       <div className="wrapper main-wrapper">
//         <Sidebar
//           // routes={currentRoutes}
//           routes={(user.title === 'Staff' || user.title === 'Volunteer') ? staffVolunteerRoutes : managerDirectorRoutes}
//         />
//         <div 
//           className="main-panel"
//           // style={{ height: '100vh' }}
//         >
//           {/* <div className="main-panel main-wrapper"> */}
//           <TurrimNavbar
//             logout={callLogout}
//           />
//           <div
//             className="content"
//             // style={{ backgroundColor: "transparent", height: '100vh' }}
//           >
//             {authenticating ? (
//               <div className="loading">Loading...</div>
//             ) : (
//               Object.keys(campaign).length > 0 ? (
//                 <Suspense fallback={<div>Loading...</div>}>
//                   {(user.title === 'Manager' || user.title === 'Director') ? (
//                     <Switch>
//                       {getRoutes(managerDirectorRoutes)}
//                       <Route path={"/turrim/walkbook/add"}>
//                         <WalkbookAdd />
//                       </Route>
//                       <Route path={"/turrim/walkbook/:name"}>
//                         <WalkbookView />
//                       </Route>
//                       <Route path={"/turrim/road-sign/add"}>
//                         <RoadSignAdd />
//                       </Route>
//                       <Route path={"/turrim/road-sign/:name"}>
//                         <RoadSignView />
//                       </Route>
//                       <Route path={"/turrim/road-sign-placement"}>
//                         <RoadSignPlacement />
//                       </Route>
//                       <Route path={"/turrim/recommendation/request"}>
//                         <RecommendedWalkbookRequest />
//                       </Route>
//                       <Route path={"/turrim/recommendation/:name"}>
//                         <RecommendationView />
//                       </Route>
//                       <Route path={"/turrim/recommendation-walkbook/:id"}>
//                         <RecommendedWalkbookView />
//                       </Route>
//                       <Route path={"/turrim/voter-group/search"}>
//                         <VoterSearch />
//                       </Route>
//                       <Route path={"/turrim/voter-group/:name"}>
//                         <VoterGroupView />
//                       </Route>
//                       <Route path={"/turrim/user/:name"}>
//                         <UserView />
//                       </Route>

//                       <Redirect from="/turrim" to="/turrim/dashboard" />

//                     </Switch>
//                   ) : (
//                     <Switch>
//                       {getRoutes(staffVolunteerRoutes)}
//                       <Route path={"/turrim/walkbook/:name"}>
//                         <WalkbookView />
//                       </Route>
//                       <Route path={"/turrim/road-sign/:name"}>
//                         <RoadSignView />
//                       </Route>
//                       <Route path={"/turrim/road-sign-placement"}>
//                         <RoadSignPlacement />
//                       </Route>
//                       {/* <Route path={"/turrim/voter-group/:name"}>
//                           <VoterGroupView />
//                         </Route> */}

//                       <Redirect from="/turrim" to="/turrim/walkbooks" />
//                     </Switch>
//                   )}
//                 </Suspense>
//               ) : (
//                 <Suspense fallback={<div>Loading...</div>}>
//                   <Switch>
//                     {getRoutes(currentRoutes)}
//                     <Redirect from="/turrim" to="/turrim/campaign" />
//                   </Switch>
//                 </Suspense>
//               ))}
//           </div>
//           <div
//             className="close-layer"
//             onClick={() => {
//               console.log("toggleSidebar from close-layer click");
//               toggleSidebar();
//               document.documentElement.classList.toggle("nav-open");
//             }}
//           />
//           {/* <div
//             className="close-layer"
//             onClick={() =>
//               document.documentElement.classList.toggle("nav-open")
//             }
//           /> */}
//         </div>
//       </div>

//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Session Expiring</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>
//             Your session will expire in {Math.floor(countdown / 60)} minutes and {countdown % 60} seconds.
//           </p>
//           {" "}
//           <p>
//             Extend your session within the remaining time or you will be signed out.
//           </p>
//         </Modal.Body>
//         <Modal.Footer>
//           {/* <Button
//             variant="secondary"
//             onClick={callLogout}
//           >
//             Sign ou
//           </Button> */}
//           <Button
//             variant="primary"
//             onClick={extendSession}
//           // style={{ cursor: "not-allowed" }}
//           >
//             Extend Session
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       {/* <div>
//         {errorMessage && <div className="error-message">{errorMessage}</div>}
//       </div> */}
//     </>
//   );
// }

// export default Turrim;